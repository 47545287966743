<template>
  <div style="background: #fbf7f3; min-height: 100%">
    <NavBar v-if="hideBack === 'false'" :title="$t('route.orderDetails')" />
    <div class="order-detail-container">
      <template v-if="isFinish">
        <!-- 订单状态 -->
        <div class="u-flex-col order-info">
          <ul>
            <li class="u-flex u-row-between u-col-top">
              <div class="u-flex u-row-between order-item">
                <p class="order-item-p">{{ `${$t("orderNumber")}：` }}</p>
                <span class="order-item-span u-flex-1">
                  {{ orderInfo.orderSn }}
                </span>
              </div>
              <span class="order-type">
                {{ orderInfo.orderStatusMsg }}
              </span>
            </li>
            <li>
              <div class="u-flex u-row-left u-col-top">
                <p class="order-item-p">{{ `${$t("orderDate")}：` }}</p>
                <span class="order-item-span u-flex-1">
                  {{ orderInfo.createTime }}
                </span>
              </div>
            </li>
            <li>
              <div class="u-flex u-row-left u-col-top">
                <p class="order-item-p">{{ `${$t("orderAmount")}：` }}</p>
                <span class="order-item-span u-flex-1">
                  {{ `HK $${orderInfo.payMoney}` }}
                </span>
              </div>
            </li>
            <li>
              <div class="u-flex u-row-left u-col-top">
                <p class="order-item-p">{{ `${$t("contactMethod")}：` }}</p>
                <span class="order-item-span u-flex-1">
                  {{ orderInfo.receivePhone }}
                </span>
              </div>
            </li>
            <li>
              <div class="u-flex u-row-left u-col-top">
                <p class="order-item-p">{{ `${$t("receivers")}：` }}</p>
                <span class="order-item-span">
                  {{ orderInfo.receiveName }}
                </span>
              </div>
            </li>
            <li>
              <div class="u-flex u-row-left u-col-top">
                <p class="order-item-p">{{ `${$t("shippingAddress")}：` }}</p>
                <span class="order-item-span u-flex-1">
                  {{ orderInfo.receiveAddress }}
                </span>
              </div>
            </li>
          </ul>
        </div>

        <!-- 发货资料 -->
        <ul
          class="shipping-information"
          v-if="
            (orderInfo.orderStatus === 4 ||
              orderInfo.orderStatus === 5 ||
              orderInfo.orderStatus === 6) &&
            orderInfo.logisticNo
          "
        >
          <li>
            <h3 class="order-content-title">{{ $t("shippingInformation") }}</h3>
          </li>
          <li class="u-flex u-row-left">
            <p class="order-item-p">{{ `${$t("deliveryDate")}：` }}</p>
            <span class="order-item-span u-flex-1">
              {{ orderInfo.deliveryTime }}
            </span>
          </li>
          <li class="u-flex u-row-left">
            <p class="order-item-p">{{ `${$t("shippingCompany")}：` }}</p>
            <span class="order-item-span u-flex-1">
              {{ orderInfo.logisticCompany }}
            </span>
          </li>
          <li class="u-flex u-row-left">
            <p class="order-item-p">{{ `${$t("postalCode")}：` }}</p>
            <span class="order-item-span u-flex-1">
              {{ orderInfo.logisticNo }}
            </span>
          </li>
        </ul>

        <!-- 订单内容 -->
        <div class="order-content">
          <h3 class="order-content-title">{{ $t("orderContent") }}</h3>
          <ul>
            <li
              v-for="(item, index) in orderInfo.goodsList"
              :key="index"
              class="u-flex u-row-between"
              style="margin-bottom: 0.3rem"
            >
              <div class="u-flex u-row-left">
                <van-image
                  width="1.8rem"
                  height="1.8rem"
                  radius="0.1rem"
                  :src="item.goodsImg"
                />
                <div class="u-flex-col u-flex-1 order-content-info">
                  <p class="order-item-name">{{ item.goodsName }}</p>
                  <div class="u-flex u-row-left">
                    <p class="order-item-p">{{ `${$t("detailed")}：` }}</p>
                    <span class="order-item-span u-flex-1">
                      {{ item.goodsSku }}
                    </span>
                  </div>
                  <div class="u-flex u-row-left">
                    <p class="order-item-p">{{ `${$t("quantity")}：` }}</p>
                    <span class="order-item-span u-flex-1">
                      {{ item.goodsQuantity }}
                    </span>
                  </div>
                  <div class="u-flex u-row-left">
                    <p class="order-item-p">{{ `${$t("unitPrice")}：` }}</p>
                    <span class="order-item-span u-flex-1">
                      {{ `HK $${item.goodsPrice}` }}
                    </span>
                  </div>
                </div>
              </div>
              <span class="price u-text-center">
                {{ `HK $${item.goodsTotalPrice}` }}
              </span>
            </li>
            <li class="u-flex u-row-between freight">
              <span>{{ $t("freight") }}</span>
              <span class="u-flex-1 u-text-right">
                {{
                  orderInfo.freightMoney
                    ? `HK $${orderInfo.freightMoney}`
                    : $t("freeShipping")
                }}
              </span>
            </li>
          </ul>

          <!-- 总金额 -->
          <div class="u-text-right total-price">
            <span>{{ `${$t("lumpSum", { n: orderInfo.totalMoney })}` }}</span>
          </div>
        </div>

        <!-- 付款资料 -->
        <ul class="payment-profile">
          <li>
            <h3 class="order-content-title">{{ $t("paymentProfile") }}</h3>
          </li>
          <li class="u-flex u-row-left">
            <p class="order-item-p">{{ `${$t("paymentDate")}：` }}</p>
            <span class="order-item-span u-flex-1">
              {{ orderInfo.paymentTime }}
            </span>
          </li>
          <li class="u-flex u-row-left">
            <p class="order-item-p">{{ `${$t("paymentMethod")}：` }}</p>
            <span class="order-item-span u-flex-1">
              {{ orderInfo.payChannelMsg }}
            </span>
          </li>
          <li class="u-flex u-row-left">
            <p class="order-item-p">{{ `${$t("referenceNumber")}：` }}</p>
            <span class="order-item-span u-flex-1">
              {{ orderInfo.outTradeNo }}
            </span>
          </li>
        </ul>

        <!-- 联系小秘书(普通会员隐藏这个联系小秘书的按钮) -->
        <div class="connect" v-if="vipLevel !== 1">
          <van-button
            :loading="loading"
            :loading-text="`${$t('contactLittleSecretary')}...`"
            plain
            type="primary"
            block
            color="#d6b87e"
            @click="gotoNewsletter"
          >
            {{ $t("contactLittleSecretary") }}
          </van-button>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { getOrderDetail, getUserVipLevel } from "@/api/order";
import NavBar from "@/components/NavBar";

export default {
  name: "orderDetails",
  components: {
    NavBar,
  },
  data() {
    return {
      // 数据加载状态
      isFinish: false,

      // 联系小秘书按钮家在状态
      loading: false,

      // 订单id
      orderId: this.$route.query.id,

      // 订单详情对象集合
      orderInfo: null,

      // 用户等级
      vipLevel: 0,
      hideBack: this.$route.query.hideBack || "false",
    };
  },
  created() {
    this.getOrderDetail();
    this.getUserVipLevel();
  },
  methods: {
    // 查询订单详情
    getOrderDetail() {
      getOrderDetail(this.orderId).then((res) => {
        if (res.code === 200) {
          this.isFinish = true;
          this.orderInfo = { ...this.orderInfo, ...res.data };
        }
      });
    },

    // 查询用户vip等级
    getUserVipLevel() {
      getUserVipLevel().then((res) => {
        if (res.code === 200) {
          this.vipLevel = res.data.vipLevel;
        }
      });
    },

    // 联系小秘书(调用mixin中客户端方法)
    gotoNewsletter() {
      this.gotoIM();
    },
  },
};
</script>

<style lang="scss" scoped>
.order-detail-container {
  padding: 0.62rem 0.4rem 0.46rem;
  background: #fbf7f3;
}

.order-info {
  font-size: 0.24rem;
  color: #515151;
  background: #ffffff;
  border-radius: 0.08rem;
  box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.18);
  border-left: 0.16rem solid #ede2cc;
  padding: 0.12rem 0 0.12rem 0.24rem;

  li:first-child {
    border-bottom: 0.04rem solid rgba(151, 151, 151, 0.12);
    div {
      color: #959392;
    }
  }

  li {
    padding: 0.12rem 0;
  }

  .order-type {
    display: block;
    background: #ede2cc;
    border-radius: 2rem 0 0 2rem;
    font-size: 0.24rem;
    color: #978055;
    padding: 0.02rem 0.16rem 0.02rem 0.24rem;
  }

  .order-item-p {
    width: 1.25rem;
    text-align: justify;
  }

  .order-item-span {
    margin-right: 0.24rem;
    word-break: break-all;
  }
}

.order-content {
  padding: 0.3rem;
  margin: 0.4rem 0;
  background: #fefdfc;
  font-size: 0.24rem;
  color: #515151;
  border-radius: 0.08rem;
  box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.18);

  .order-content-title {
    font-size: 0.32rem;
    font-weight: 600;
    color: #515151;
  }

  .order-content-info {
    padding: 0.1rem 0 0.1rem 0.24rem;
    line-height: 0.4rem;
  }

  .order-item-name {
    font-size: 0.3rem;
    font-weight: 500;
    color: #b57c4f;
  }

  .order-item-p {
    width: 0.72rem;
    text-align: justify;
  }

  .order-item-span {
    word-break: break-all;
  }

  .price {
    min-width: 1.44rem;
    height: 0.72rem;
    line-height: 0.72rem;
    border: 0.02rem solid #f9f2eb;
    border-radius: 0.08rem;
    color: #959392;
    padding: 0 0.05rem;
    word-break: break-all;
  }

  .freight {
    font-size: 0.28rem;
    font-weight: 500;
    color: #755a24;
    margin: 0.36rem 0;
  }

  .total-price {
    font-size: 0.28rem;
    font-weight: 500;
    color: #755a24;
    border-top: 0.03rem solid rgba(151, 151, 151, 0.12);
    padding-top: 0.28rem;

    > span {
      display: inline-block;
      padding: 0.16rem 0.2rem;
      background: #fbf7f3;
      border-radius: 0.08rem;
    }
  }
}

.shipping-information {
  margin: 0.4rem 0;
}

.payment-profile,
.shipping-information {
  padding: 0.3rem;
  background: #fefdfc;
  font-size: 0.24rem;
  color: #515151;
  border-radius: 0.08rem;
  box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.18);
  line-height: 0.55rem;

  .order-content-title {
    font-size: 0.32rem;
    font-weight: 600;
    color: #515151;
    margin-bottom: 0.1rem;
  }

  .order-item-p {
    width: 1.25rem;
    text-align: justify;
  }

  .order-item-span {
    margin-right: 0.24rem;
    word-break: break-all;
  }
}

.connect {
  padding: 0.4rem 9% 0;

  .van-button {
    background: #fbf7f3;
    border-radius: 0.44rem;
    font-size: 0.25rem;
  }
}
</style>
